import { memo } from 'react';

import { ErrorTemplate } from '../ErrorTemplate';
import { text } from './helpers';

export const Error500 = memo(() => (
  <ErrorTemplate text={text} title="500" />
));

export default Error500;
