import { memo } from 'react';
import Link from 'next/link';

import { Box } from 'uikitv2/Box';
import { Icon } from 'uikitv2/Icon'
import { ErrorTemplateProps } from './types';
import classes from './ErrorTemplate.module.scss';

export const ErrorTemplate = memo<ErrorTemplateProps>(({ text, title }) => {
  return (
    <Box className={classes.wrap} justifyContent="center" alignItems="center">
      <Box className={classes.content}>
        <Box className={classes.left} alignItems="center" justifyContent="flex-start">
          <Box direction="column" className={classes.group} justifyContent="space-between">
            <h3 className={classes.title}>{title}</h3>
            <Box direction="column">
              <p className={classes.text}>{text}</p>
              <Link href="/" className={classes.link}>
                Home
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className={classes.right} justifyContent="center" alignItems="center">
          <Icon name="caution_big" />
        </Box>
      </Box>
    </Box>
  );
});
